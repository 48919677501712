import axios from 'axios';
import { urlMap } from '@/common/config';
import { ElMessageBox, ElMessage } from 'element-plus';
import { loading } from '../common/utils';
import router from '../router/index'
import store from '../store/index'
import * as Sentry from '@sentry/browser'

class RequestError extends Error {
  constructor(message) {
    super()
    this.name = 'RequestError'
    this.message = message
  }
}

console.log('process.env', process.env)
axios.interceptors.request.use(
  config => {
    let url = config.url
    // get参数编码
    if (config.method === 'get' && config.params) {
      url += '?'
      let keys = Object.keys(config.params)
      for (let key of keys) {
        if (config.params[key] != undefined) {
          url += `${key}=${encodeURIComponent(config.params[key])}&`
        }
      }
      url = url.substring(0, url.length - 1)
      config.params = {}
    }
    config.url = url
    return config
  }
)

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://192.168.2.70:31002',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    console.log("request => ", config.url,  config.data)
    loading.open();
    let token = window.localStorage.getItem('token');
    if (token) {
      config.headers.token = token;
    }
    let url = config.url
    // get参数编码
    if (config.method === 'get' && config.params) {
      url += '?'
      let keys = Object.keys(config.params)
      for (let key of keys) {
        url += `${key}=${encodeURIComponent(config.params[key])}&`
      }
      url = url.substring(0, url.length - 1)
      config.params = {}
    }
    config.url = url
    return config 
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    loading.close();
    const res = response.data
    console.log('response => ', res)
    // if the custom code is not 20000, it is judged as an error.
    if (!res.success) {
      if (res.code === -1) {
        window.localStorage.removeItem('token')
        router.push({ path: '/login' })
        return Promise.reject(res.message || 'Error')
      }
      Sentry.withScope(scope => {
        scope.setExtra('request', response.config)
        scope.setExtra('response', response.data)
        Sentry.captureException(new RequestError(res))
      })
      ElMessage({
        message: res.message || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        ElMessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          // store.dispatch('user/resetToken').then(() => {
          //   location.reload()
          // })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res.data
    }
  },
  error => {
    loading.close();
    console.log(error) // for debug
    Sentry.withScope(scope => {
      scope.setExtra('request', error.response?.config)
      scope.setExtra('response', error.response?.data)
      Sentry.captureException(error)
    })
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)


function request(url, data={}, options={}) {
  if (!url.includes('http')) {
    url = urlMap[url] || url;
  }
  return service({
    url: url,
    method: 'POST',
    data,
    ...options
  })
}

function GET (url, data = {}, options = {}) {
  if (!url.includes('http')) {
    url = urlMap[url] || url;
  }
  return service({
    url: url,
    method: 'GET',
    params: data,
    ...options
  })
}

function uploadFile (data = {}, options = {}) {
  const url = urlMap['uploadFile']
  return axios({
    method: 'post',
    url: url,
    data: data,
    headers: { 'content-type': 'multipart/form-data' },
    ...options
  })
  .then(function (response) {
    console.log('uploadFile --> res', response.data);
    return Promise.resolve(response.data)
  })
  .catch(function (error) {
    console.log('uploadFile --> err', error);
    return Promise.reject(error)
  })
}
function downloadFile (url, data = {}, options = {}) {
  url = urlMap[url] || url
  let token = window.localStorage.getItem('token');
  loading.open();
  return axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      token
    },
    responseType: 'blob',
    ...options
  })
  .then(function (response) {
    console.log('downloadFile --> res', response)
    loading.close()
    return Promise.resolve(response.data)
  })
  .catch(function (error) {
    console.log('downloadFile --> err', error)
    loading.close()
    return Promise.reject(error)
  })
}

async function authInit () {
  try {
    if (!window.localStorage.getItem('token')) return
    const menus = await request('getUserMenus') || []
    store.commit('setUserMenus', menus)
  } catch (error) {
    console.log(error)
  }
}

export {
  urlMap,
  service,
  request,
  GET,
  uploadFile,
  downloadFile,
  authInit
}
