<template>
  <div class="sidebar">
    <div class="sidebar-fold">
      <i class="el-icon-s-unfold" v-if="isCollapse" @click="isCollapse=!isCollapse"></i>
      <i class="el-icon-s-fold" v-else @click="isCollapse=!isCollapse"></i>
    </div>
    <div class="user-container">
      <!-- <div class="avatar">
      </div> -->
      <div class="user-icon">
        <i class="el-icon-user"></i>
      </div>
      <div class="user-name">{{userInfo.userName}}
        <!-- <i class="iconfont icon-ota-xiajiantou"></i> -->
      </div>
    </div>
    <!-- <div class="menu-container"> -->
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
      >
        <template v-for="item in routes" :key="item.path">
          <template v-if="checkRouteAuth(item, item.path)">
            <template v-if="item && item.children && item.children.length === 1">
              <app-link :to="compilePath(item.path, item.children[0].path)">
                <el-menu-item
                  :index="compilePath(item.path, item.children[0].path)"
                >
                  <i :class="item.meta.icon" class="iconfont"></i>
                  <template #title>{{ item.meta.title }}</template>
                </el-menu-item>
              </app-link>
            </template>
            <template
              v-else-if="item && item.children && item.children.length > 1"
            >
              <el-submenu :index="item.path">
                <template #title>
                  <i class="iconfont" :class="item.meta.icon"></i>
                  <span>{{ item.meta.title }}</span>
                </template>
                <template v-for="child in item.children" :key="child.path">
                  <app-link :to="compilePath(item.path, child.path)" v-if="checkRouteAuth(child, compilePath(item.path, child.path))">
                    <el-menu-item :index="compilePath(item.path, child.path)">
                      {{ child.meta.title }}
                    </el-menu-item>
                  </app-link>
                </template>
              </el-submenu>
            </template>
          </template>
        </template>
      </el-menu>
    <!-- </div> -->
  </div>
</template>

<script>
import { routes } from "@/router";
import path from "path";
import AppLink from "./link";
import { request } from "@/utils/request";

export default {
  components: { AppLink },
  data() {
    return {
      userInfo: {},
      isCollapse: false,
      routes: [],
      menus: []
    };
  },
  computed: {
    activeMenu() {
      return this.$route.path;
    },
  },
  created() {
    try {
      const userInfo = window.localStorage.getItem('userInfo')
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo) || {}
      }
    } catch (error) {
      console.error(error)
    }
    this.getData()
  },
  mounted() {
  },
  methods: {
    async getData() {
      try {
        this.menus = await request('getUserMenus') || []
        this.routes = routes
        this.$store.commit('setUserMenus', this.menus)
      } catch (error) {
        console.error(error)
      }
    },
    compilePath(itemPath, childPath) {
      // console.log("sidebar new");
      // console.log(itemPath + ", " + childPath);
      return path.resolve(itemPath, childPath);
    },
    handleOpen() {},
    handleClose() {},
    checkRouteAuth(item, path) {
      if (item.meta && item.meta.hide) return false
      if (item.meta && !item.meta.auth) return true
      // 过滤菜单权限
      for (let menu of this.menus) {
        if (menu.path === path) return true
        for (let sub of menu.children||[]) {
          if (sub.path === path) return true
        }
      }
      return false
    }
  },
};
</script>

<style lang="scss" scoped>

.sidebar {
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}
.sidebar-fold {
  font-size: 20px;
  i {
    padding: 10px;
    float: right;
  }
}
.user-icon {
  position: relative;
  font-size: 32px;
  margin: auto;
}
.el-menu-vertical {
  height: 80%;
  // width: 100%;
  text-align: left;
  overflow-y: auto;
  // min-height: 400px;
  // padding-bottom: 20px;
}
// .menu-container {
//   position: absolute;
//   text-align: left;
//   top: 220px;
//   bottom: 0;
//   width: 100%;
// }



.iconfont {
  font-size: 22px;
  color: #666;
}
.icon-ota-xiajiantou {
  font-size: 13px;
  color: #555;
}
.user-container {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  pointer-events: none;
}
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: #00ffff8c;
  position: relative;
  top: 42px;
  margin: 0 auto;
  background-image: url(https://gw.alicdn.com/tps/TB1W_X6OXXXXXcZXVXXXXXXXXXX-400-400.png);
  background-size: 90%;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
}
.user-name {
  height: 16px;
  font-size: 14px;
  text-align: center;
  position: relative;
}
:deep(.el-menu-item.is-active) {
  color: #d80c1e;
  font-weight: 600;
}
:deep(.el-menu-item:hover) {
  background-color: rgba(216, 12, 30, 0.3);
}
:deep(.el-menu-item:focus) {
  background-color: #fff;
}
:deep(.el-submenu.is-active) {
  background-color: #d80c1e;
}
:deep(.el-submenu__title:hover) {
  background-color: rgba(216, 12, 30, 0.3);
}

.is-active .el-submenu__title span {
  color: #fff;
}

@media (max-width: 500px) {
  .sidebar {
    position:absolute;
    width: 100%;
    z-index: 5000;
  }
}
</style>
