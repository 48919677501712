<template>
  <section class="app-main">
    <div class="background-top">
      <div class="breadcrumb-container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <template v-for="item in routeMatched" :key="item.path">
            <el-breadcrumb-item :to="item.path">{{ item.meta.title }}</el-breadcrumb-item>
          </template>
        </el-breadcrumb>
      </div>
    </div>
    <router-view v-slot="{ Component }" :key="key">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <component :is="Component"/>
        </keep-alive>
     </transition>
    </router-view>
  </section>
</template>

<script>
// import Breadcrumb from './breadcrumb/index'
export default {
  name: "AppMain",
  // components: {Breadcrumb},
  computed: {
    key() {
      console.log("this.$route.path: " + this.$route.path);
      return this.$route.path;
    },
    routeMatched() {
      return this.$route.matched
    }
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  // min-height: calc(100vh - 50px);
  // height: calc(100vh - 90px);
  height: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
.background-top {
  width: 100%;
  height: 100px;
  float: left;
  background-color: #D60C1E;
  position: absolute;
  z-index: 1;
}
.breadcrumb-container {
  position: relative;
  left: 25px;
  top: 20px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>

<style scoped>
.el-breadcrumb  :deep(.el-breadcrumb__inner) {
  color: #fff;
}
.el-breadcrumb__item:last-child :deep(.el-breadcrumb__inner) {
  color: rgba(255, 255, 255, 0.8)
}
</style>