import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {},
    menus: []
  },
  mutations: {
    setUserInfo (state, data) {
      state.userInfo = data
    },
    setUserMenus (state, data) {
      state.menus = data
    }
  },
  actions: {
  },
  modules: {
  }
})