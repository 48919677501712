import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
// import 'element-plus/theme-chalk/index.css'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { APP_ENV } from '@/common/config'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { authInit } from './utils/request';
import './utils/wxLogin'


// require('./mock');
authInit().then(() => {
  const app = createApp(App)
  Sentry.init({
    dsn:
    APP_ENV == 'prd'
        ? 'https://39d4c44367354bdcacbe925e4223db75@track.jaka.com/15'
        : 'http://39d4c44367354bdcacbe925e4223db75@172.30.0.105:9000/15',
    environment: APP_ENV == 'prd' ? 'prod' : 'test',
    integrations: [new Integrations.ExtraErrorData()],
    ignoreErrors: ['ResizeObserver loop completed with undelivered notifications.']
  })

  app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .mount('#app')
}).catch((err) => {
  console.log(err)
})