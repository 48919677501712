import { ElLoading } from 'element-plus';
const loading = {
  instance: null,
  open: function(options={}) {
    this.instance = ElLoading.service({
      text: '加载中',
      background: 'rgba(50,50,50,.1)',
      ...options
    })
  },
  close: function() {
    this.instance && this.instance.close()
  }
}
window.loading = loading;


const formatTime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${[year, month, day].map(formatNumber).join('-')}`
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : `0${n}`
}

function getUrlParams (path) {
  if (!path) return
  path = decodeURIComponent(path)
  var data = {}
  var reg = new RegExp('([^?=&]+)(=([^&]*))?', 'g')
  path && path.replace(reg, function ($0, $1, $2, $3) {
    data[$1] = $3
  })
  return data
}

function getUrlParam (path, name) {
  path = decodeURIComponent(path)
  const reg = new RegExp('(^|&|\\?)' + name + '=([^&]*)(&|$)')
  const res = path.match(reg)
  let value = ''
  if (res) {
    value = res[2]
  }
  return value
}

const intValidator = ({ min, max, label }) => {
  return [
    {
      required: true,
      message: `请输入${label}`,
      trigger: "blur",
    },
    {
      pattern: /^[0-9]+$/,
      message: '请输入有效正整数',
      trigger: "blur",
    },
    {
      validator: (rule, value) => {
        const intValue = parseInt(value);
        const minValue = min || 0; // 设置最小值
        const maxValue = max || 9999999; // 设置最大值
        if (intValue < minValue || intValue > maxValue) {
          return Promise.reject(
            `不在合理范围内(${minValue} ~ ${maxValue})`
          );
        } else {
          return Promise.resolve();
        }
      },
      trigger: "blur",
    },
  ];
};

export {
  loading,
  formatTime,
  getUrlParam,
  getUrlParams,
  intValidator
}
