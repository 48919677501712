<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </el-config-provider>
</template>
<script>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    }
  },
  created() {
  },
  mounted() {
    // if (!window.localStorage.getItem('token')) {
    //   this.$router.push({ path: '/login' })
    // }
  }
}
</script>
<style lang="scss">
@import "./icons/iconfont.css";
@import "@/styles/base.scss";
@import "@/styles/element-ui.scss";

.tox-tinymce-aux, .tox-tinymce-inline {
  z-index: 709826033 !important;
}

</style>
